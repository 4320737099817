export const VCARD_DATA = "VCARD_DATA";
export const ROSTER_DATA = "ROSTER_DATA";
export const ROSTER_DATA_UPSERT = "ROSTER_DATA_UPSERT";
export const ROSTER_DATA_UPDATE = "ROSTER_DATA_UPDATE";
export const RECENT_CHAT_DATA = "RECENT_CHAT_DATA";
export const RECENT_ROSTER_UPDATE = "RECENT_ROSTER_UPDATE";
export const RECENT_RECALL_UPDATE = "RECENT_RECALL_UPDATE";
export const RECENT_REMOVE_MESSAGE_UPDATE = "RECENT_REMOVE_MESSAGE_UPDATE";
export const MESSAGE_DATA = "MESSAGE_DATA";
export const ACTIVE_CHAT_DATA = "ACTIVE_CHAT_DATA";
export const RECENT_NOTIFICATION_UPDATE = "RECENT_NOTIFICATION_UPDATE";
export const RECENT_STATUS_UPDATE = "RECENT_STATUS_UPDATE";
export const TYPED_CONTENT = "TYPED_CONTENT";
export const POPUP_STATE = "POPUP_STATE";
export const ACTIVE_CHAT_UPDATE = "ACTIVE_CHAT_UPDATE";
export const PROFILE_IMAGE_CHAT_DATA = "PROFILE_IMAGE_CHAT_DATA";
export const VCARD_CONTACT_DATA_ACTION = "VCARD_CONTACT_DATA_ACTION";
export const GROUPS_DATA = "GROUPS_DATA";
export const GROUPS_UPDATE_DATA = "GROUPS_UPDATE_DATA";
export const GROUPS_MEMBER_DATA = "GROUPS_MEMBER_DATA";
export const GROUPS_MEMBER_PARTICIPANTS_LIST_DATA = "GROUPS_MEMBER_PARTICIPANTS_LIST_DATA";
export const LAST_ACTIVITY_DATA = "LAST_ACTIVITY_DATA";
export const BLOCK_DATA = "BLOCK_DATA";
export const BLOCK_STATUS = "BLOCK_STATUS";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const POPUP_SHOW_HIDE = "POPUP_SHOW_HIDE";
export const CONNECTION_STATE_DATA = "CONNECTION_STATE_DATA";
export const FEATURE_STATE_DATA = "FEATURE_STATE_DATA";
export const SINGLE_CHAT_MESSAGE_HISTORY = "SINGLE_CHAT_MESSAGE_HISTORY";
export const SINGLE_CHAT_MESSAGE_REMOVE = "SINGLE_CHAT_MESSAGE_REMOVE";
export const SINGLE_CHAT_MESSAGE_RECALL = "SINGLE_CHAT_MESSAGE_RECALL";
export const SINGLE_CHAT_MESSAGE_RESET = "SINGLE_CHAT_MESSAGE_RESET";
export const GROUP_CHAT_MESSAGE_HISTORY = "GROUP_CHAT_MESSAGE_HISTORY";
export const GROUP_CHAT_MESSAGE_REMOVE = "GROUP_CHAT_MESSAGE_REMOVE";
export const GROUP_CHAT_MESSAGE_UPDATE_STATUS = "GROUP_CHAT_MESSAGE_UPDATE_STATUS";
export const GROUP_CHAT_MESSAGE_RECALL = "GROUP_CHAT_MESSAGE_RECALL";
export const GROUP_CHAT_MESSAGE_RESET = "GROUP_CHAT_MESSAGE_RESET";
export const PRESENCE_STATUS = "PRESENCE_STATUS";
export const TYPING_STATUS = "TYPING_STATUS";
export const TYPING_STATUS_REMOVE = "TYPING_STATUS_REMOVE";
export const UNREAD_MESSAGE_ADD = "UNREAD_MESSAGE_ADD";
export const UNREAD_MESSAGE_UPDATE = "UNREAD_MESSAGE_UPDATE";
export const UNREAD_MESSAGE_DELETE = "UNREAD_MESSAGE_DELETE";
export const REDUCE_UNREAD_MESSAGE_COUNT = "REDUCE_UNREAD_MESSAGE_COUNT";
export const GROUP_NOTIFICATION_MSG_COUNT = "GROUP_NOTIFICATION_MSG_COUNT";
export const UNREAD_USER_OBJ = "UNREAD_USER_OBJ";
export const REPLY_MESSAGE_DATA = "REPLY_MESSAGE_DATA";
export const REPLY_MESSAGE_RESET = "REPLY_MESSAGE_RESET";
export const CALLCONNECTION_STATE_DATA = "CALLCONNECTION_STATE_DATA";
export const CONFRENCE_POPUP_STATUS = "CONFRENCE_POPUP_STATUS";
export const RESET_CONFRENCE_POPUP_STATUS = "RESET_CONFRENCE_POPUP_STATUS";
export const SINGLE_CHAT_MEDIA = "SINGLE_CHAT_MEDIA";
export const SINGLE_CHAT_MEDIA_RESET = "SINGLE_CHAT_MEDIA_RESET";
export const SINGLE_CHAT_MEDIA_UPDATE = "SINGLE_CHAT_MEDIA_UPDATE";
export const GROUP_CHAT_MEDIA_ACTION = "GROUP_CHAT_MEDIA_ACTION";
export const GROUP_CHAT_MEDIA_RESET = "GROUP_CHAT_MEDIA_RESET";
export const GROUP_CHAT_MEDIA_UPDATE = "GROUP_CHAT_MEDIA_UPDATE";
export const SINGLE_CHAT_SELECTED_MEDIA = "SINGLE_CHAT_SELECTED_MEDIA";
export const SINGLE_CHAT_SELECTED_MEDIA_RESET = "SINGLE_CHAT_SELECTED_MEDIA_RESET";
export const GROUP_CHAT_SELECTED_MEDIA = "GROUP_CHAT_SELECTED_MEDIA";
export const GROUP_CHAT_SELECTED_MEDIA_RESET = "GROUP_CHAT_SELECTED_MEDIA_RESET";
export const MESSAGE_INFO_DATA = "MESSAGE_INFO_DATA";
export const MESSAGE_INFO_UPDATE = "MESSAGE_INFO_UPDATE";
export const MESSAGE_FORWARD_ADD = "MESSAGE_FORWARD_ADD";
export const MESSAGE_FORWARD_REMOVE = "MESSAGE_FORWARD_REMOVE";
export const MESSAGE_FORWARD_RESET = "MESSAGE_FORWARD_RESET";
export const CREATE_BROADCAST = "CREATE_BROADCAST";
export const UPDATE_BROADCAST = "UPDATE_BROADCAST";
export const BROADCAST_RESET = "BROADCAST_RESET";
export const CURRENT_CALL_GROUP_MEMBERS = "CURRENT_CALL_GROUP_MEMBERS";
export const INSERT_CALL_LOG = "INSERT_CALL_LOG";
export const DELETE_CALL_LOG = "DELETE_CALL_LOG";
export const CALLLOG = "calllog";
export const CLEAR_ALL = "clear_all";
export const CLEAR_MESSAGE = "clear_message";
export const FETCHING_CALL_LOG = "FETCHING_CALL_LOG";
export const CALL_CONVERSION = "CALL_CONVERSION";
export const PIN_USER = "PIN_USER";
export const LARGE_VIDEO_USER = "LARGE_VIDEO_USER";
export const CALL_DURATION_TIMESTAMP = "CALL_DURATION_TIMESTAMP";
export const DELETE_SINGLE_CHAT = "DELETE_SINGLE_CHAT";
export const RESET_TO_DEFAULT = "RESET_TO_DEFAULT";
export const UPDATE_ROSTER_LAST_MESSAGE = "UPDATE_ROSTER_LAST_MESSAGE";
export const RESET_CALL_LOG = "RESET_CALL_LOG";
export const BROWSER_NOTIFY = "BROWSER_NOTIFY";
export const UPDATE_GROUP_NAME = "UPDATE_GROUP_NAME";
export const CONTACT_WHO_BLOCKED_ME = "CONTACT_WHO_BLOCKED_ME";
export const SCROLL_DOWN_CHAT_HISTORY = "SCROLL_DOWN_CHAT_HISTORY";
export const BROWSER_TAB_VISIBLE_ACTIVITY = "BROWSER_TAB_VISIBLE_ACTIVITY";
export const APP_ONLINE_STATUS = "APP_ONLINE_STATUS";
export const CHAT_SEEN_PENDING_MSG = "CHAT_SEEN_PENDING_MSG";
export const DELETE_CHAT_SEEN_PENDING_MSG = "DELETE_CHAT_SEEN_PENDING_MSG";
export const CLEAR_CHAT_SEEN_PENDING_MSG = "CLEAR_CHAT_SEEN_PENDING_MSG";
export const ROSTER_DATA_ADD = "ROSTER_DATA_ADD";
export const RESET_UNREAD_COUNT = "RESET_UNREAD_COUNT";
export const RESET_RECENT_CHAT = "RESET_RECENT_CHAT";
export const UPDATE_MSG_BY_LAST_MSGID = "UPDATE_MSG_BY_LAST_MSGID";
export const CHAT_MESSAGE_HISTORY = "CHAT_MESSAGE_HISTORY";
export const UPDATE_MESSAGE_STATUS = "UPDATE_MESSAGE_STATUS";
export const UPDATE_UPLOAD_STATUS = "UPDATE_UPLOAD_STATUS";
export const CLEAR_CHAT_HISTORY = "CLEAR_CHAT_HISTORY";
export const CLEAR_CHAT_HISTORY_ACTION_COMMON = "CLEAR_CHAT_HISTORY_ACTION_COMMON";
export const CHAT_MESSAGE_EDIT = "CHAT_MESSAGE_EDIT";
export const DELETE_CHAT_HISTORY = "DELETE_CHAT_HISTORY";
export const DELETE_MESSAGE_FOR_ME = "DELETE_MESSAGE_FOR_ME";
export const DELETE_MESSAGE_FOR_EVERYONE = "DELETE_MESSAGE_FOR_EVERYONE";
export const CANCEL_MEDIA_UPLOAD = "CANCEL_MEDIA_UPLOAD";
export const RETRY_MEDIA_UPLOAD = "RETRY_MEDIA_UPLOAD";
export const UPDATE_TYPED_MESSAGE = "UPDATE_TYPED_MESSAGE";
export const MODAL_ACTIVE_CLASS = "MODAL_ACTIVE_CLASS";
export const RECONNECT_RECENT_CHAT_UPDATE = "RECONNECT_RECENT_CHAT_UPDATE";
export const AUDIO_CALL_MUTE = "AUDIO_CALL_MUTE";
export const UPDATE_FAVOURITE_STATUS = "UPDATE_FAVOURITE_STATUS";
export const STARRED_MESSAGEE_LIST = "STARRED_MESSAGEE_LIST";
export const UPDATE_STARRED_MESSAGE_LIST = "UPDATE_STARRED_MESSAGE_LIST";
export const REMOVE_ALL_STARRED_MESSAGE = "REMOVE_ALL_STARRED_MESSAGE";
export const REMOVE_ALL_FAVOURITE_STATUS = "REMOVE_ALL_FAVOURITE_STATUS";
export const TRANSLATE_MESSAGE = "TRANSLATE_MESSAGE";
export const REMOVE_STARRED_MESSAGE_CLEAR_CHAT = "REMOVE_STARRED_MESSAGE_CLEAR_CHAT";
export const REMOVE_STARRED_MESSAGE_DELETE_CHAT = "REMOVE_STARRED_MESSAGE_DELETE_CHAT";
export const UPDATE_STARRED_MESSAGE_STATUS = "UPDATE_STARRED_MESSAGE_STATUS";
export const UPDATE_MUTE_RECENT_CHAT = "UPDATE_MUTE_RECENT_CHAT";
export const LOCAL_STROAGE_CHANGES = "LOCAL_STROAGE_CHANGES";
export const INITIAL_TRANSLATE_MESSAGE = "INITIAL_TRANSLATE_MESSAGE";
export const STORE_TRANSLATE_LANGUAGES = "STORE_TRANSLATE_LANGUAGES";
export const UPDATE_ARCHIVE_CHAT = "UPDATE_ARCHIVE_CHAT";
export const UPDATE_ARCHIVE_RECENT_CHAT = "UPDATE_ARCHIVE_RECENT_CHAT";
export const CLEAR_ALL_CHAT = "CLEAR_ALL_CHAT";
export const CALL_INTERMEDIATE_SCREEN = "CALL_INTERMEDIATE_SCREEN";
export const RESET_CALL_INTERMEDIATE_SCREEN = "RESET_CALL_INTERMEDIATE_SCREEN";
export const ADMIN_BLOCK_LIST = "ADMIN_BLOCK_LIST";
export const ROSTER_PERMISSION = "ROSTER_PERMISSION";
export const CALL_STAR_ORIGINAL_MESSAGE = "CALL_STAR_ORIGINAL_MESSAGE";
export const CALL_STAR_ORIGINAL_MESSAGE_ROW_DATA = "CALL_STAR_ORIGINAL_MESSAGE_ROW_DATA";
export const FETCHING_USER_LIST = "FETCHING_USER_LIST";
export const SELECTED_MESSAGE_INFO = "SELECTED_MESSAGE_INFO";
export const DOWNLOADING_MEDIA = "DOWNLOADING_MEDIA";
export const CANCEL_MEDIA_DOWNLOAD = "CANCEL_MEDIA_DOWNLOAD";
export const MEDIA_DROPDOWNSTATUS = "MEDIA_DROPDOWNSTATUS";
export const MEDIA_IMG_THUMB = "MEDIA_IMG_THUMB";
export const LOAD_MORE_CHATMESSAGES = "LOAD_MORE_CHATMESSAGES";
export const CALL_QUALITY = "CALL_QUALITY";
export const CALL_POOR_CONNECTION_STATUS_POPUP = "CALL_POOR_CONNECTION_STATUS_POPUP";
export const CALL_POOR_CONNECTION_STATUS_ICON = "CALL_POOR_CONNECTION_STATUS_ICON";
export const RECONNECT_GROUPS_UPDATE_DATA = "RECONNECT_GROUPS_UPDATE_DATA";
export const UPDATE_SETTINGS_DATA = "UPDATE_SETTINGS_DATA";
export const UPDATE_ACTIVE_CHAT_DATA = "UPDATE_ACTIVE_CHAT_DATA";